import React, { useEffect, useRef } from "react";
import UsernameForm from "../components/UsernameForm";
import ColourLogo from "../assets/LogoColour.svg";
import OfferModal from "../components/OfferModal";

import Confetti from "react-confetti";

const ClaimPoints = ({
  setUserName,
  userName,
  setShowRewardsPage,
  showOfferModal,
  setOfferModal,
  postClaimPoints,
  setPointsData,
  usernameError,
  setUsernameError,
}) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const timeout = useRef();
  const TIMEOUT_DUR = 30000;

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setShowRewardsPage(false);
      setPointsData(null);
      setUsernameError(false);
    }, TIMEOUT_DUR);
    //eslint-disable-next-line
  }, [userName]);

  return (
    <div className="bg-[#DCF5EB] min-h-[100vh]">
      <Confetti width={width} height={height} />
      {showOfferModal && (
        <OfferModal
          modalState={showOfferModal}
          toggleModal={setOfferModal}
          username={userName}
        />
      )}
      <div className="container flex flex-col gap-5 justify-center items-center  m-auto">
        <img src={ColourLogo} className="w-36 h-36 mt-10" alt="colorLogo" />
        <h1 className="text-6xl text-[#04756F] font-bold">Congratulations!</h1>
        <h2 className="text-4xl text-[#04756F] font-bold">
          You've helped make the world a little better
        </h2>
        <h2 className="text-4xl text-[#04756F] font-bold">
          Enter your username to claim your points
        </h2>
        {usernameError && (
          <h3>Please re-enter your username, something went wrong!</h3>
        )}
        <UsernameForm
          postClaimPoints={postClaimPoints}
          setUser={setUserName}
          userName={userName}
        />
      </div>
    </div>
  );
};

export default ClaimPoints;
