import React from "react";

const LeaderboardItem = ({ username, points, rank, year }) => {
  return (
    <tr
      className={`${
        rank % 2 === 0 ? "bg-[rgb(73,69,79)] bg-opacity-[0.08]" : "bg-none"
      } h-[48px]`}
    >
      <td className="p-2 border-r border-[#04756F] text-left w-[160px]">
        {rank}
      </td>
      <td className="p-2 border-r border-[#04756F] text-left w-[260px]">
        {username}
      </td>
      <td className="p-2 border-r border-[#04756F] text-left w-[160px]">
        {year}
      </td>
      <td className="p-2 border-r border-[#04756F] text-left w-[160px]">
        {points}
      </td>
    </tr>
  );
};

export default LeaderboardItem;
