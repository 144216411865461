import React from "react";
import ExportLeaderboard from "../components/ExportLeaderboard";
import LeaderBoard from "../components/Leaderboard";

import LogoWhite from "../assets/LogoWhite.svg";
import ColourLogo from "../assets/LogoColour.svg";
import HomeLogo from "../assets/HomeIcon.svg";
import LightbulbIcon from "../assets/lightbulbIcon.svg";

const Dashboard = ({ leaderboardData, getLeaderBoard }) => {
  const facts = ['The UK recycles around 380,000 tonnes of plastic bottles a year.', 'In landfills, plastic can take up to 1,000 years to completely decompose.', 'There will be more plastic in the ocean than fish by 2050. Currently, around 3.5 trillion fish are living in the ocean.', 'Approximately 7% of all plastic bottles are recycled globally.', '75% less energy is consumed to make a plastic bottle from recycled plastic than using virgin material.', '1 tonne of plastic translates into 25,000 plastic bottles', 'A majority of plastic bottles and containers are made from Polyethylene terephthalate (PET) or High-density polyethylene (HDPE).', 'Microplastics can be found in every form of water we have, including tap water and have been found in humans digestive tracts.', 'Each minute, the equivalent of a truckload of plastic pollutes the oceans.' ,'73% of beach waste globally is plastic; this includes cigarette butt filters, bottles, bottle tops, food wrappers, shopping bags, and polystyrene containers.' , 'Nearly a million plastic water bottles are sold every minute worldwide.', 'Almost half of all plastic waste produced worldwide comprises packaging material, much of which is never recycled or incinerated.']
  const randomFact = facts[Math.floor(Math.random()*facts.length)]  
  return (
    <div
      className={`${
        leaderboardData && leaderboardData.length > 0
          ? "bg-[#DCF5EB]"
          : "bg-white"
      } min-h-[100vh]`}
    >
      {leaderboardData && leaderboardData.length > 0 ? (
        <div className="container flex flex-col justify-center items-center m-auto">
          <div className="flex justify-between w-full mb-[32px]">
            <img className="h-[124px] m-[36px]" src={ColourLogo} alt="" />
            <div className="flex justify-center items-center">
              <h1 className="text-[#047565] text-[48px] pr-[40px]">
                Leaderboard
              </h1>
              <ExportLeaderboard leaderboardData={leaderboardData} />
            </div>
            <img className="m-[36px] h-[36px]" src={HomeLogo} alt="" />
          </div>
          <LeaderBoard leaderboardData={leaderboardData} />
          <button onClick={getLeaderBoard}  className="rounded-3xl bg-[#04756F] py-2 px-4 mt-6 text-white">Refresh LeaderBoard</button>

        </div>
      ) : (
        <div>
          <div className="flex justify-center items-center">
            <img
              src={LogoWhite}
              className="w-40 h-40 absolute mx-auto top-12"
              alt=""
            />
          </div>
          <div className="w-full h-72 bg-[#5ac07c] border-b-8  border-[#33854E] rounded-br-[400px] rounded-bl-[150px]"></div>
          <div className="container flex flex-col justify-center items-center  m-auto">
            <div className="max-w-[805px] my-[90px] bg-[#1BBC9B] border-[5px] border-[#04756F] rounded-[12px] mx-[20px]">
              <h1 className="text-[50px] text-white mx-[127px] font-[600] text-center max-w-[551px] my-[60px]">
                Feed me a plastic bottle to earn points
              </h1>
              <div className="flex w-[100%] justify-center">
                <img
                  className="w-[28px] h-[28px] mr-[12px]"
                  src={LightbulbIcon}
                  alt=""
                />
                <p className="text-white text-center font-[600] text-[24px]">
                  Did you know:
                </p>
              </div>
              <p className="text-white text-center font-[600] text-[24px] mb-[52px]">
                {randomFact}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
