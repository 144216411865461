import React from "react";
import { CSVLink } from "react-csv";

const ExportLeaderboard = ({ leaderboardData }) => {
  for (var i = 0; i < leaderboardData.length; i++) {
    leaderboardData[i].rank = i + 1;
  }

  const headers = [
    { label: "Rank", key: "rank" },
    { label: "Username", key: "username" },
    { label: "Year", key: "year" },
    { label: "Points", key: "points" },
  ];

  //get date for filename
  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1;
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  const csvLeaderboard = {
    data: leaderboardData,
    headers: headers,
    filename: `Leaderboard_${day}_${month}_${year}.csv`,
  };

  return (
    <div>
      <CSVLink
        className="bg-[#047565] text-[white] px-[24px] py-[10px] rounded-[100px] md:hidden lg:block"
        {...csvLeaderboard}
      >
        Export
      </CSVLink>
    </div>
  );
};

export default ExportLeaderboard;
