import React from "react";

const UsernameForm = ({ postClaimPoints, setUser, userName }) => {
  return (
    <>
      <div className="mt-8 flex flex-col gap-4">
        <input
          className="py-3 rounded-md w-72 px-4 focus:border-green-800 ring-transparent focus:ring-green-800 border-b-2 border-black"
          placeholder="Username"
          value={userName}
          id="username"
          onChange={(e) => setUser(e.target.value)}
        />
        <button
          className="rounded-3xl bg-[#04756F]  text-white w-32 py-3 px-4"
          onClick={postClaimPoints}
        >
          Claim Points
        </button>
        <button className="rounded-3xl bg-transparent border-[#04756F] border-2 text-[#04756F] w-52 py-2 px-4 mt-4">
          <span className="flex flex-row items-center justify-evenly text-[#04756F]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            Create New User
          </span>
        </button>
      </div>
    </>
  );
};

export default UsernameForm;
