import React, { useState, useEffect, useRef } from "react";
import "./App.css";
// import Lottie from 'react-lottie';
import LogoWhite from "./assets/LogoWhite.svg";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import Axios from "axios";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./amplify_config.json";
import AdminModaLogin from "./components/AdminModalLogin";
import ClaimPoints from "./pages/ClaimPoints";
Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [pointsData, setPointsData] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const intervalID = useRef();
  const [showRewardsPage, setShowRewardsPage] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState(false);

  user && console.log(user);

  // GET LEADERBOARD
  const getLeaderboard = async () => {
    const token = await (await Auth.currentSession())
      .getIdToken()
      .getJwtToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      await Axios.post(
        "https://te16ymuz33.execute-api.eu-west-2.amazonaws.com/dev/go-green-recycle/get/leaderboard/",
        {},
        config
      ).then((res) => {
        const data = res.data.leaderboard;
        data && data.sort((a, b) => b["points"] - a["points"]);
        data && setLeaderboardData(data);
      });
    } catch (error) {
      console.log("Leaderboard not received", error.stack);
    }
  };

  // GET POINTS TOKEN
  const getPointsData = async () => {
    const token = await (await Auth.currentSession())
      .getIdToken()
      .getJwtToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    try {
      await Axios.post(
        "https://te16ymuz33.execute-api.eu-west-2.amazonaws.com/dev/go-green-recycle/get/latest/points/token",
        {},
        config
      ).then((res) => {
        console.log(res.data);
        if (typeof res.data !== "string") {
          setPointsData(res.data);
          setShowRewardsPage(true);
          clearInterval(intervalID.current);
          intervalID.current = null;
          console.log(intervalID.current);
        }
      });
    } catch (error) {
      console.log("Data not retrieved", error.stack);
    }
  };

  // POST CLAIM POINT
  const postClaimPoints = async () => {
    const token = await (await Auth.currentSession())
      .getIdToken()
      .getJwtToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      await Axios.post(
        `https://te16ymuz33.execute-api.eu-west-2.amazonaws.com/dev/go-green-recycle/claim/points/${userName}/${pointsData.token_id}`,
        {},
        config
      ).then((res) => {
        setUsernameError(false);
        // show offermodal
        setOfferModal(true);
        setTimeout(() => {
          setPointsData(null);
          intervalID.current = setInterval(function () {
            getPointsData();
          }, 3000);
          setOfferModal(false);
          setShowRewardsPage(false);
        }, 4000);
      });
    } catch (error) {
      console.log("Claim Data not retrieved", error.stack);
      setUserName("");
      setUsernameError(true);
    }
  };

  useEffect(() => {
    if (!showRewardsPage && !intervalID.current)
      intervalID.current = setInterval(function () {
        getPointsData();
      }, 3000);
  }, [showRewardsPage]);

  // Auth
  const assessLoggedInState = () => {
    Auth.currentAuthenticatedUser()
      .then((session) => {
        console.log("test");
        getLeaderboard();
        setLoggedIn(true);
        console.log(!intervalID.current);
        if (!intervalID.current)
          intervalID.current = setInterval(function () {
            getPointsData();
          }, 3000);
        console.log(intervalID.current);
      })
      .catch((err) => {
        console.log(err);
        setLoggedIn(false);
      });
  };

  useEffect(() => {
    assessLoggedInState();
    //eslint-disable-next-line
  }, []);
  return loggedIn === true ? (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              showRewardsPage ? (
                <ClaimPoints
                  setUserName={setUserName}
                  userName={userName}
                  postClaimPoints={postClaimPoints}
                  setShowRewardsPage={setShowRewardsPage}
                  showOfferModal={offerModal}
                  setPointsData={setPointsData}
                  usernameError={usernameError}
                  setUsernameError={setUsernameError}
                />
              ) : (
                <Dashboard
                  leaderboardData={leaderboardData}
                  pointsData={pointsData}
                  showRewardsPage={setShowRewardsPage}
                  getLeaderBoard={getLeaderboard}
                />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  ) : (
    <>
      <BrowserRouter>
        <div className="login-container">
          <div className="lock-icon-container"></div>
          <div className="min-h-screen h-full bg-white">
            <AdminModaLogin
              modalState={modalState}
              setModalToggle={setModalState}
              setUser={setUser}
              onSignIn={assessLoggedInState}
            />
            <div className="flex justify-center items-center">
              <img
                src={LogoWhite}
                className="w-40 h-40 absolute mx-auto top-5"
                alt=""
              />
            </div>
            <div className="w-full h-72 bg-[#5ac07c] border-b-8  border-[#33854E] rounded-br-[400px] rounded-bl-[150px]"></div>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
