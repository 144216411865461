import React from "react";
import LeaderboardItem from "./LeaderboardItem";

const LeaderBoard = ({ leaderboardData }) => {
  return (
    <div className="leaderboard max-h-[528px] overflow-y-auto">
      <table className="table-auto border border-[#04756F]">
        <thead className="bg-[#1fb79c] sticky top-0">
          <tr className="text-[16px]">
            <td className="p-2 border-b border-r border-[#04756F] text-left h-[48px]">
              Rank
            </td>
            <td className="p-2 border-b border-r border-[#04756F] text-left h-[48px]">
              Username
            </td>
            <td className="p-2 border-b border-r border-[#04756F] text-left h-[48px]">
              Year
            </td>
            <td className="p-2 border-b border-r border-[#04756F] text-left h-[48px]">
              Points
            </td>
          </tr>
        </thead>
        <tbody>
          {leaderboardData &&
            leaderboardData.map((person, i) => (
              <LeaderboardItem
                key={person.username}
                username={person.username}
                points={person.points}
                year={person.year}
                rank={i + 1}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaderBoard;
