import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import boopSfx from "../assets/CardinalHeenanCatholicSportsCollege2.m4a";
import boopSfxV2 from "../assets/CardinalHeenanCatholicSportsCollege.m4a";
import boopSfxV3 from "../assets/CardinalHeenanCatholicSportsCollege3.m4a";
import boopSfxV4 from "../assets/CardinalHeenanCatholicSportsCollege4.m4a";
import boopSfxV5 from "../assets/CardinalHeenanCatholicSportsCollege5.m4a";
import boopSfxV6 from "../assets/CardinalHeenanCatholicSportsCollege6.m4a";
import boopSfxV7 from "../assets/CardinalHeenanCatholicSportsCollege7.m4a";
import boopSfxV8 from "../assets/CardinalHeenanCatholicSportsCollege8.m4a";
import useSound from "use-sound";

import SmilingEarth from "../assets/SmilingEarthFinalV2.gif";
const listOfSounds = [
  boopSfx,
  boopSfxV2,
  boopSfxV3,
  boopSfxV4,
  boopSfxV5,
  boopSfxV6,
  boopSfxV7,
  boopSfxV8,
];
export default function OfferModal({ modalState, toggleModal, username }) {
  const [play, { stop }] = useSound(
    listOfSounds[Math.floor(Math.random() * listOfSounds.length)]
  );

  useEffect(() => {
    play();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Transition appear show={modalState} as={Fragment}>
        <Dialog
          open={modalState}
          onClose={() => {
            stop();
            toggleModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#DCF5EB] " />
          </Transition.Child>

          <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-full overflow-y-auto">
            <div className="flex items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl border-4 border-[#04756F] bg-[#DCF5EB] p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex py-10 justify-center text-6xl font-bold leading-6 text-[#04756F] self-center"
                  >
                    Congratulations
                  </Dialog.Title>

                  <div className="flex flex-col justify-center items-center gap-5"></div>

                  <div className="flex flex-col justify-center items-center gap-5">
                    <img src={SmilingEarth} alt="" className=" bg-contain" />
                    <h2 className="text-4xl text-[#04756F] font-bold">
                      {username}
                    </h2>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
